import React from "react";

const HomeBannerImage = (props) => {
    return (
        <video
            src="https://ggfx-corelondon.s3.eu-west-2.amazonaws.com/i.prod/core_london_3cc9b86612.mp4"
            alt="banner-img"
            className="banner-img"
            autoPlay="autoplay"
            playsInLine="playsinline"
            loop="true"
            muted="true"
        />
    )

}

export default HomeBannerImage